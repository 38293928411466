export enum PaymentMethod {
     SPB = 'sbp',
     TINKOFF = 'tinkoff',
     SBERBANK = 'sberbank',
     ALFABANK = 'alfabank',
     MIR = 'mir',
     VTB = 'vtb',
     ROSSELHOZBANK = 'rosselhozbank',
     RAIFFEISENBANK = 'raiffeisenbank',
     QIWI = 'qiwi',
     YOOMONEY = 'yoomoney',
     VISA_MASTERCARD = 'visa_mastercard',
     PAYEER = 'payeer',
     PHONE = 'phone',
}