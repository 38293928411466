import {PaymentMethod} from "@/enum/PaymentMethod";

class PaymentMethodsHelper {
    public getLogo(method: PaymentMethod): string {
        try {
            const icon = require('@/assets/img/methods/' + method + '.png');

            return icon ? icon : require('@/assets/img/methods/unknown_method.svg');
        } catch (e) {
            return require('@/assets/img/methods/unknown_method.svg');
        }
    }
}

export const paymentMethodsHelper = new PaymentMethodsHelper();
